




























import Vue from 'vue';
import Component from 'vue-class-component';
import {authApi} from '@/api';
import {ResourceType} from '@/enums';
import {authModule} from '@/store/modules';
import {AlertMessage} from '@/interfaces';
// eslint-disable-next-line no-undef
import CredentialResponse = google.accounts.id.CredentialResponse;

@Component
export default class Auth extends Vue {
  private alert: AlertMessage | null = null;
  private loading = false;

  async created() {
    // we only need to check this single getter since if we lose Google Auth we
    // sign out the user from the API
    if (authModule.isAuthenticated) {
      const redirect = !this.$route.query.redirect
        ? 'dashboard'
        : decodeURIComponent(this.$route.query.redirect as string);
      await this.$router.push(redirect);
    }
  }
  async mounted() {
    // eslint-disable-next-line no-undef
    google.accounts.id.initialize({
      // eslint-disable-next-line @typescript-eslint/camelcase
      client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID as string,
      //scope: 'https://www.googleapis.com/auth/userinfo.profile',
      callback: async (response: CredentialResponse) => {
        this.loading = true;
        if (!response || !response.credential) {
          this.alert = {
            type: 'error',
            message:
              'An error occurred during Google Sign In. Please try again.',
          };
          this.loading = false;
          return;
        }
        await authApi.post({
          type: ResourceType.Auth,
          attributes: {idToken: response.credential},
        });
        await authModule.checkAuth(response.credential);

        if (authModule.isAuthenticated) {
          this.alert = {
            type: 'success',
            message: 'Redirecting to dashboard',
          };

          const redirect = !this.$route.query.redirect
            ? 'dashboard'
            : decodeURIComponent(this.$route.query.redirect as string);
          await this.$router.push(redirect);
        } else {
          this.alert = {
            type: 'error',
            message: 'An error occurred during sign in.',
          };
        }
        this.loading = false;
      },
    });

    // eslint-disable-next-line no-undef
    google.accounts.id.renderButton(
      document.getElementById('sign-in') as HTMLElement,
      {
        type: 'standard',
        shape: 'rectangular',
        theme: 'filled_blue',
        text: 'signin_with',
        size: 'large',
        width: '300px',
      }
    );
    // eslint-disable-next-line no-undef
    //google.accounts.id.prompt(); // also display the One Tap dialog
  }
}
